import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import datamapLogo from "../images/datamap-logo.svg"
import { Container } from "@material-ui/core"
import clsx from "clsx"
import useScroll from "../hooks/useScroll"
import { breakpoints } from "./variables"
import { isLoggedIn } from "./authentication"

const HeaderW = styled.header`
  position: fixed;
  width: 100%;
  padding: 1rem 0;
  background-color: ${props => props.theme.palette.background.header};
  box-shadow: 0px 1px 10px rgba(251, 251, 251, 0.8);
  transition: padding 0.5s;
  z-index: 1;
  &.shrink {
    padding: 0.2rem 0;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0.5rem 0;
  }
`
const HeaderIW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`
const Logo = styled(Link)`
  display: flex;
  align-items: flex-end;
  .logo {
    display: block;
    margin-right: 0.5rem;
    width: 4rem;
  }
  .text {
    font-size: 1.4rem;
    margin-bottom: 0.7rem;
  }
`
const MenuBarW = styled.div`
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`
const RequestDemoLink = styled(Link)`
  border: 1px solid ${props => props.theme.palette.sage.main};
  border-radius: 1.5rem;
  padding: 0.4rem 0.8rem;
  color: ${props => props.theme.palette.sage.main};
  &:hover {
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 0.8rem;
  }
`
const LoginLink = styled.a`
  margin-right: 1rem;
  transition: color 0.5s;
  &:hover {
    color: ${props => props.theme.palette.sage.main};
  }
`
const DashboardLink = styled.a`
  margin-right: 1rem;
  transition: color 0.5s;
  &:hover {
    color: ${props => props.theme.palette.sage.main};
  }
`
const LoginAndDemoW = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    border-top: 1px solid ${props => props.theme.palette.datamap.main};
  }
`

const Header = () => {
  const scrollPosition = useScroll()
  const [loginLink, setLoginLink] = useState(
    "https://datamap.tools/Account/Login"
  )

  useEffect(() => {
    const checkLoginStatus = async () => {
      if (localStorage.getItem("isNewCustomer")) setLoginLink("/login")
      if (await isLoggedIn()) setLoginLink("/dashboard/")
    }
    checkLoginStatus()
  }, [setLoginLink])

  return (
    <HeaderW className={clsx({ shrink: scrollPosition.y > 64 })}>
      <Container maxWidth="lg">
        <HeaderIW>
          <Logo className="logo" to="/">
            <img className="logo" src={datamapLogo} alt="datamap.logo" />
            <div className="text">DataMap.tools</div>
          </Logo>
          <MenuBarW className="menuBarW">
            <LoginAndDemoW className="loginAndDemoW">
              {loginLink === "/dashboard/" ? (
                <DashboardLink href={loginLink}>Dashboard</DashboardLink>
              ) : (
                <LoginLink href={loginLink}>Login</LoginLink>
              )}
              <RequestDemoLink to="/request-demo">REQUEST DEMO</RequestDemoLink>
            </LoginAndDemoW>
          </MenuBarW>
        </HeaderIW>
      </Container>
    </HeaderW>
  )
}

export default Header
