import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import styled from "styled-components"

import Header from "./header"
import Footer from "./footer"

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  body {
    color: rgb(8, 31, 58);
    background-color: rgb(251, 251, 251);
    font-family: "Montserrat-Regular", sans-serif;
    margin: 0;
  }
  img {
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`

const Main = styled.main`
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow: hidden;
  position: relative;
`

const theme = {
  palette: {
    primary: {
      main: "rgb(251, 251, 251)",
    },
    error: {
      main: "#f44336",
      light: "#ff9800",
    },
    datamap: {
      main: "rgb(8,31,59)",
      light: "rgb(31, 110, 206)",
    },
    sage: {
      main: "rgb(43, 120, 29)",
      light: "rgb(63, 181, 41)",
      dark: "rgb(0,126,101)",
    },
    background: {
      main: "rgb(251, 251, 251)",
      header: "rgb(255, 255, 255)",
    },
  },
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main>{children}</Main>
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
