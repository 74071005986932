export const breakpoints = {
  xs: "0px",
  sm: "600px",
  md: "960px",
  lg: "1280px",
  xl: "1920px",
}

export const role = {
  superAdmin: "Super Admin",
  admin: "Admin",
  user: "User",
}

export const permission = {
  viewConcurSettings: "View Concur Settings",
}
