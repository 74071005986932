import React from "react"
import styled from "styled-components"
import { InputBase, Button } from "@material-ui/core"
import { useState } from "react"
import clsx from "clsx"
import * as EmailValidator from "email-validator"
import { breakpoints } from "./variables"
import axios from "axios"

const SubmitW = styled.div`
  width: ${({ width }) => width};
  display: flex;
  padding: 0.3rem 0.3rem 0.3rem 0.7rem;
  border-radius: 2rem;
  background-color: ${props => props.theme.palette.primary.main};
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 90%;
  }
`
const SuccessCover = styled.div`
  position: absolute;
  left: -100%;
  top: 1px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  border-radius: 2rem;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.sage.light};
  transition: left 1s;
  &.active {
    left: 1px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`
const InvalidCover = styled.div`
  position: absolute;
  left: -100%;
  top: 1px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  border-radius: 2rem;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.error.light};
  transition: left 1s;
  &.active {
    left: 1px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`
const ErrorCover = styled.div`
  position: absolute;
  left: -100%;
  top: 1px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  border-radius: 2rem;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.error.light};
  transition: left 1s;
  &.active {
    left: 1px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`
const SInputBase = styled(InputBase)`
  font-size: 1.4rem;
  color: ${props => props.theme.palette.datamap.main};
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`
const SButton = styled(Button)`
  border-radius: 2rem;
  font-size: 1.2rem;
  padding: 0.5rem 1.4rem;
  color: ${props => props.theme.palette.background.main};
  background-color: ${props => props.theme.palette.sage.main};
  &:hover {
    background-color: ${props => props.theme.palette.sage.dark};
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`
const EmailSubmitter = ({ width, className }) => {
  const [success, setSuccess] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [error, setError] = useState(false)
  const [emailAddress, setEmailAddress] = useState("")
  const handleEmailAddressChange = event => {
    const emailAddress = event.target.value
    setEmailAddress(emailAddress)
  }
  const handleSubmitEmail = () => {
    if (EmailValidator.validate(emailAddress)) {
      axios({
        method: "POST",
        url: "https://webapi.datamap.tools",
        headers: {
          "content-type": "application/json",
        },
        data: { email: emailAddress },
      })
        .then(() => {
          setSuccess(true)
          setTimeout(() => {
            setEmailAddress("")
            setSuccess(false)
          }, 2000)
        })
        .catch(() => {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 2000)
        })
    } else {
      setInvalid(true)
      setTimeout(() => {
        setInvalid(false)
      }, 2000)
    }
  }
  return (
    <SubmitW width={width} className={`root ${className}`}>
      <SInputBase
        fullWidth
        placeholder="Email Address"
        inputProps={{ "aria-label": "email address" }}
        className={`textField ${className}`}
        value={emailAddress}
        onChange={handleEmailAddressChange}
      />
      <SButton
        type="submit"
        aria-label="search"
        className={`submit ${className}`}
        onClick={handleSubmitEmail}
      >
        Submit
      </SButton>
      <SuccessCover
        className={clsx("successCover", className, { active: success })}
      >
        Thank You!
      </SuccessCover>
      <InvalidCover
        className={clsx("invalidCover", className, { active: invalid })}
      >
        Please Enter Valid Email.
      </InvalidCover>
      <ErrorCover className={clsx("errorCover", className, { active: error })}>
        Please try again.
      </ErrorCover>
    </SubmitW>
  )
}

export default EmailSubmitter
