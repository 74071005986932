import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container } from "@material-ui/core"
import datamapLogo from "../images/datamap-logo.svg"
import twitterLogo from "../images/social-networks/twitter.svg"
import facebookLogo from "../images/social-networks/facebook.svg"
import EmailSubmitter from "./email-submitter"
import { breakpoints } from "./variables"

const FooterW = styled.footer`
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.background.main};
  padding: 3rem 0;
`
const FooterIW = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
  padding-bottom: 2rem;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-wrap: wrap;
  }
`
const LinksW = styled.div`
  @media screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg}) {
    margin-right: 1rem;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 48%;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
    &:nth-child(2) {
      margin-top: 1rem;
    }
  }
`
const Title = styled.h1`
  margin-top: 0;
  font-size: 2rem;
  font-weight: bold;
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
  }
`
const SLink = styled(Link)`
  font-size: 1.3rem;
  display: block;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.2rem;
  }
`
const SimpleLink = styled.a`
  font-size: 1.3rem;
  display: block;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.2rem;
  }
`
const SubmitW = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    width: 48%;
    padding-top: 2rem;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`
const SubmitDescription = styled.p`
  font-size: 1.3rem;
`
const LogoAndSocialW = styled.div`
  @media screen and (max-width: ${breakpoints.md}) {
    width: 48%;
    text-align: center;
    padding-top: 2rem;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`
const Logo = styled.img`
  margin-top: 1rem;
  width: 100%;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(298deg)
    brightness(106%) contrast(97%);
  @media screen and (max-width: ${breakpoints.md}) {
    width: 30%;
    margin-top: 2rem;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    width: 25%;
  }
`
const SocialsW = styled.div`
  display: flex;
  justify-content: center;
`
const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  &:not(:last-child) {
    margin-right: 1rem;
  }
`
const SocialImage = styled.img`
  display: block;
  width: 2.5rem;
  height: 2.5rem;
`
const SEmailSubmitter = styled(EmailSubmitter)`
  background-color: ${props => props.theme.palette.background.main};
  &.textField {
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 1rem;
    }
  }
  .submit {
    color: ${props => props.theme.palette.background.main};
    background-color: ${props => props.theme.palette.datamap.main};
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 1rem;
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: 0.8rem;
    }
  }
  .successCover {
    background-color: ${props => props.theme.palette.datamap.main};
  }
  .invalidCover {
    background-color: ${props => props.theme.palette.datamap.main};
    color: ${props => props.theme.palette.error.light};
  }
`
const CopyWrite = styled.div`
  margin-top: 2rem;
  @media screen and (max-width: ${breakpoints.sm}) {
    text-align: center;
  }
`

const Footer = () => {
  return (
    <FooterW>
      <Container maxWidth="lg">
        <FooterIW>
          <LinksW>
            <Title>Company</Title>
            <SLink to="/">Home</SLink>
            <SLink to="/support">Support</SLink>
            <SLink to="/request-demo">Request Demo</SLink>
            <SimpleLink href="/how-it-works">How it works</SimpleLink>
          </LinksW>
          <LinksW>
            <Title>About Us</Title>
            <SLink to="/contact-us">Contact Us</SLink>
            <SLink to="/privacy-policy">Privacy Policy</SLink>
            <SLink to="/terms-and-conditions">Terms and Conditions</SLink>
          </LinksW>
          <SubmitW>
            <Title>Submit Your Email</Title>
            <SubmitDescription>
              we will contact you as soon as possible
            </SubmitDescription>
            <SEmailSubmitter width="95%" />
          </SubmitW>
          <LogoAndSocialW>
            <SocialsW>
              <SocialLink
                href="https://twitter.com/DataMaptools"
                aria-label="twitter"
                target="_blank"
                rel="noreferrer"
              >
                <SocialImage src={twitterLogo} alt="twitter logo"></SocialImage>
              </SocialLink>
              <SocialLink
                href="https://www.facebook.com/DataMap.tools"
                aria-label="facebook"
                target="_blank"
                rel="noreferrer"
              >
                <SocialImage
                  src={facebookLogo}
                  alt="facebook logo"
                ></SocialImage>
              </SocialLink>
            </SocialsW>
            <Logo src={datamapLogo} alt="datamap logo" />
          </LogoAndSocialW>
        </FooterIW>
        <CopyWrite>Copyright © 2020 DataMap. All Rights Reserved</CopyWrite>
      </Container>
    </FooterW>
  )
}

export default Footer
