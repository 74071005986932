import { useState, useEffect, useCallback } from "react"

const useScroll = () => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  })
  const callback = useCallback(() => {
    setPosition({ x: window.scrollX, y: window.scrollY })
  }, [])
  useEffect(() => {
    window.addEventListener("scroll", callback)
    return () => {
      window.removeEventListener("scroll", callback)
    }
  })
  return position
}

export default useScroll
